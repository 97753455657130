import * as React from 'react';
import SingleWidgetTile from './components/SingleWidgetTile';
import { OfferingDomain } from './domain/offering-domain';
import { OfferingDto } from '@wix/bookings-uou-domain';
import { OfferingDisplayOptions } from './business-logic/offering-display-options';
import { WixEnvironment } from './components/SingleWidgetTile/SingleWidgetTile';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import * as moment from 'moment';
import { ISantaProps } from '@wix/native-components-infra/dist/es/src/types/types';

interface WidgetViewerMainProps extends WidgetViewerBookingsProps {
  host: ISantaProps;
}

export interface WidgetViewerBookingsProps {
  getBusinessId: Function;
  locale: string;
  offering: OfferingDto;
  offeringDisplayOptions: OfferingDisplayOptions;
  cssBaseUrl: string;
  translations: any;
  bookingPossible: boolean;
  ravenUserContextOverrides: any;
  sendViewerOpenedEventCallback: Function;
  offeringActionCallback: Function;
  bookItFailureCallback: Function;
  isRTL: boolean;
  canReportLoading: boolean;
}

export const enum DeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export class WidgetViewerMain extends React.Component<WidgetViewerMainProps> {
  createOfferingDomainInstance = () => {
    return OfferingDomain.fromDto(this.props.offering);
  };

  render() {
    const { appLoadBI, formFactor, dimensions } = this.props.host;
    const {
      offeringDisplayOptions,
      offeringActionCallback,
      locale,
      translations,
      bookingPossible,
      bookItFailureCallback,
      sendViewerOpenedEventCallback,
      canReportLoading,
    } = this.props;
    try {
      moment.defineLocale(locale, {
        months: translations['months-full'].split('_'),
        monthsShort: translations['months-short'].split('_'),
      });
      moment.locale(locale);
    } catch (e) {
      throw new Error(
        `Could not fetch translations for locale: ${locale} caused by: ${e.message}`,
      );
    }

    const environment: WixEnvironment = {
      deviceType: formFactor.toLowerCase() as DeviceType,
      isEditorMode: this.props.host.viewMode.toLowerCase() === 'editor',
    };
    const onLoaded = appLoadBI.loaded;
    const onLoadedStep = appLoadBI.startLoading || (() => {});
    const offeringDomainInstance = this.createOfferingDomainInstance();
    const onAction = (intent, offeringId, biReferral) =>
      offeringActionCallback(intent, biReferral);
    let containerStyle;
    if (environment.isEditorMode) {
      containerStyle = {
        height: `${dimensions.height}px`,
        width: '100%',
      };
    } else {
      containerStyle = {
        height: `${dimensions.height}px`,
        width: `${dimensions.width}px`,
        overflow: 'hidden',
      };
    }
    return (
      <I18nextProvider i18n={i18n(locale, translations)}>
        <div style={containerStyle as any}>
          <SingleWidgetTile
            offeringDomain={offeringDomainInstance}
            offeringDisplayOptions={offeringDisplayOptions}
            environment={environment}
            onLoaded={onLoaded}
            onLoadedStep={onLoadedStep}
            onAction={onAction}
            dimensions={dimensions}
            bookingPossible={bookingPossible}
            bookItFailureCallback={bookItFailureCallback}
            sendViewerOpenedEventCallback={sendViewerOpenedEventCallback}
            canReportLoading={canReportLoading}
          />
        </div>
      </I18nextProvider>
    );
  }
}
